<template>
  <div>
    <sg-carousel-modal v-model="photoModal" :images="photos"/>

    <div class="is-flex is-justify-content-end">
      <b-button type="is-primary"
                class="has-shadow is-gradient mb-3"
                @click="addDisease"
                v-if="canAddDisease">
        {{ $t('add') }} Data
      </b-button>
    </div>

    <b-table :mobile-cards="false" :data="data">
      <template #empty>
        <p class="has-text-centered">{{ $t('no') }} Data</p>
      </template>

      <b-table-column field="pond" :label="$t('farm.pond')" v-slot="props">
        {{ props.row.pond_name }}
      </b-table-column>
      <b-table-column field="created_at" :label="$t('date')" v-slot="props">
        {{ $dateFormat(props.row.created_at) }}
      </b-table-column>
      <b-table-column field="doc" label="DOC" v-slot="props">
        {{ props.row.stock ? $getDOC(props.row.stock.stock_date, props.row.created_at) : '-' }}
      </b-table-column>
      <b-table-column field="disease_name" :label="$t('disease')" v-slot="props">
        {{ props.row.disease_name }}
      </b-table-column>
      <b-table-column field="image" :label="$t('image')" v-slot="props">
        <div class="is-flex image-list">
          <span @click="showImageModal(props.row.photos)" v-for="(image, key) in props.row.photos" :key="key">
          <img
              v-if="image.photo"
              class="img-sm"
              :src="image.photo"
              alt=""
          />
          </span>
        </div>
      </b-table-column>

      <b-table-column field="action" label="" v-slot="props" v-if="canEditDisease">
        <div class="is-flex">
          <b-button class="mr-2" type="is-secondary is-light" @click="editDisease(props.row)" icon-left="pencil-outline"></b-button>
          <b-button type="is-danger is-light" @click="deleteDisease(props.row.disease_id)" icon-left="trash-can-outline"></b-button>
        </div>
      </b-table-column>
    </b-table>

    <!--  Modal Disease -->

    <b-modal
        v-model="isModalOpen"
        has-modal-card
        :destroy-on-hide="true"
        aria-role="dialog"
        aria-modal>
      <template #default="props">
        <input-disease is-modal @close="props.close" @submit="getData(true)" :default-value="disease_form"/>
      </template>
    </b-modal>

  </div>
</template>

<script>
import InputDisease from "@/components/Farm/Sidebar/InputDisease";
import SgCarouselModal from "@/components/Sg/SgCarouselModal";
import {mapGetters} from "vuex";

export default {
  name: "Disease",
  components: {SgCarouselModal, InputDisease},
  data: () => {
    return {
      data: [],
      disease_form: null,
      isModalOpen: false,
      isImageModalActive: true,

      photos: [],
      photoModal: false,
    }
  },
  computed: {
    ...mapGetters('farm', [
        'canAddDisease',
        'canEditDisease',
    ]),
    ponds() {
      return this.$store.state.farm.overview.ponds
    },
    refresh_data() {
      return this.$store.state.farm.overview.refresh_data
    }
  },
  watch: {
    refresh_data(value) {
      if (value) this.getData(true)
    },
  },
  created() {
    this.getData(true)
  },
  methods: {
    addDisease () {
      this.disease_form = null
      this.isModalOpen = true
    },

    showImageModal(photos) {
      this.photos = photos.map(e => e.photo)
      this.photoModal = true
    },

    async getData(refresh = false) {
      this.$loading()
      this.data = []
      for (let i = 0; i < this.ponds.length; i++) {
        let pond = this.ponds[i]
        if (pond.selected_cycle) {

          if (!pond.stock) {
            pond.stock = await this.$store.dispatch('farm/getStockOverview', pond)
          }

          if (refresh || !pond.disease) {
            pond.disease = []
            let disease = await this.$store.dispatch('farm/getDisease', pond.selected_cycle.cycle_id)
            if (!disease.error && disease.data && disease.data.length) {
              pond.disease = disease.data.map(e => {
                e.block_id = pond.block_id
                e.pond_name = pond.name
                e.stock = pond.stock.find(f => f.cycle_id === e.cycle_id)
                return e
              })
            }
          }
        }
      }
      this.data = this.ponds.reduce((a, b) => {
        if (b.disease) a = a.concat(b.disease)
        return a
      }, [])
      this.$loading(false)
      this.$store.dispatch('farm/refreshOverview', false)
    },
    editDisease(disease) {
      this.disease_form = this._.cloneDeep(disease)
      this.isModalOpen = true
    },
    deleteDisease(id) {
      this.$delete(async () => {
        this.$loading()
        await this.$store.dispatch('farm/deleteDisease', id)
        await this.getData(true)
        this.$loading(false)
      })
    },
  }
}
</script>
