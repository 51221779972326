<template>
  <div>
    <div class="is-flex is-justify-content-space-between is-align-items-center is-flex-wrap-wrap">
      <div class="btn-tabs mb-3">
        <b-button :type="filterType === $t('all') ? 'is-primary is-light' : 'is-text'"
                  class="has-text-weight-bold mr-2"
                  @click="filterData($t('all'))">{{ $t('all') }}
        </b-button>
        <b-button :type="filterType === 'Total' ? 'is-primary is-light' : 'is-text'"
                  class="has-text-weight-bold mr-2"
                  @click="filterData('Total')">Total
        </b-button>
        <b-button :type="filterType === n ? 'is-primary is-light' : 'is-text'"
                  class="has-text-weight-bold mr-2"
                  v-for="n in totalPartial"
                  :key="totalPartial - n + 1"
                  @click="filterData(n)">{{ $t('harvest.partial') }} {{ n }}
        </b-button>
      </div>
      <b-button type="is-primary"
                class="mb-3 has-shadow is-gradient ml-auto"
                @click="addHarvest"
                v-if="canAddHarvest">
        {{ $t('harvest.harvest') }} {{ $t('shrimp') }}
      </b-button>
    </div>
    <div>
      <b-table :mobile-cards="false" sticky-header :data="data">
        <template #empty>
          <p class="has-text-centered">{{ $t('no') }} Data</p>
        </template>

        <b-table-column field="pond" :label="$t('farm.pond')" v-slot="props">
          {{ props.row.pond_name }}
        </b-table-column>
        <!--
                <b-table-column field="total" :label="$t('harvest.harvest')" v-slot="props" cell-class="has-text-centered">
                  <b-tag rounded type="is-primary is-light">{{ props.row.is_harvest_total ? 'Total' : $t('harvest.partial') }}</b-tag>
                </b-table-column>
        -->
        <b-table-column field="created_at" :label="$t('date')" v-slot="props">
          {{ $dateFormat(props.row.harvest_date) }}
        </b-table-column>
        <b-table-column field="doc" label="DOC" v-slot="props">
          {{ props.row.stock ? $getDOC(props.row.stock.stock_date, props.row.harvest_date) : '-' }}
        </b-table-column>
        <b-table-column field="size" label="Size" v-slot="props">
          {{ $numberFormat(props.row.size) }}
        </b-table-column>
        <b-table-column field="mbw" label="MBW" v-slot="props">
          {{ $numberFormat(props.row.mbw) }}
        </b-table-column>
        <b-table-column field="fresh" label="Fresh" v-slot="props">
          {{ props.row.fresh }} Kg
        </b-table-column>
        <b-table-column field="fresh_price" label="Price/Kg" v-slot="props" v-if="cost_enabled">
          {{ $currency(props.row.fresh_price) }}
        </b-table-column>
        <b-table-column field="undersize" label="Undersize" v-slot="props">
          {{ props.row.undersize }} Kg
        </b-table-column>
        <b-table-column field="undersize_price" label="Price/Kg" v-slot="props" v-if="cost_enabled">
          {{ $currency(props.row.undersize_price) }}
        </b-table-column>
        <b-table-column field="molting" :label="$t('harvest.molting')" v-slot="props">
          {{ props.row.molting }} Kg
        </b-table-column>
        <b-table-column field="molting_price" label="Price/Kg" v-slot="props" v-if="cost_enabled">
          {{ $currency(props.row.molting_price) }}
        </b-table-column>
        <b-table-column field="defective" :label="$t('harvest.defective')" v-slot="props">
          {{ props.row.defective }} Kg
        </b-table-column>
        <b-table-column field="defective_price" label="Price/Kg" v-slot="props" v-if="cost_enabled">
          {{ $currency(props.row.defective_price) }}
        </b-table-column>
        <b-table-column field="hollow" :label="$t('harvest.hollow')" v-slot="props">
          {{ props.row.hollow }} Kg
        </b-table-column>
        <b-table-column field="hollow_price" label="Price/Kg" v-slot="props" v-if="cost_enabled">
          {{ $currency(props.row.hollow_price) }}
        </b-table-column>
        <b-table-column field="others" :label="$t('harvest.others')" v-slot="props">
          {{ props.row.others }} Kg
        </b-table-column>
        <b-table-column field="others_price" label="Price/Kg" v-slot="props" v-if="cost_enabled">
          {{ $currency(props.row.others_price) }}
        </b-table-column>
        <!--        <b-table-column field="harvest_cost" label="Cost" v-slot="props" v-if="cost_enabled">-->
        <!--          {{ $currency(props.row.harvest_cost) }}-->
        <!--        </b-table-column>-->

        <b-table-column field="action" label="" v-slot="props" v-if="canEditHarvest">
          <div class="is-flex">
            <b-button class="mr-2" type="is-secondary is-light"
                      @click="editHarvest(props.row)" icon-left="pencil-outline"></b-button>
            <b-button type="is-danger is-light"
                      :disabled="props.row.is_harvest_total"
                      @click="deleteHarvest(props.row.harvest_id)" icon-left="trash-can-outline"></b-button>
          </div>
        </b-table-column>
      </b-table>

      <!--  Modal Harvest -->

      <b-modal
          v-model="isModalOpen"
          has-modal-card
          :destroy-on-hide="true"
          aria-role="dialog"
          aria-modal>
        <template #default="props">
          <input-stock-harvest is-modal @close="props.close" @submit="getData(true)" :default-value="harvestForm" type="harvest"/>
        </template>
      </b-modal>
    </div>
  </div>
</template>

<script>
import InputStockHarvest from "@/components/Farm/Sidebar/InputStockHarvest";
import {mapGetters} from "vuex";

export default {
  name: "Harvest",
  components: {InputStockHarvest},
  data() {
    return {
      data: [],
      harvestForm: null,
      isModalOpen: false,

      totalPartial: 0,
      filterType: this.$t('all')
    }
  },
  computed: {
    ...mapGetters('farm', [
      'canAddHarvest',
      'canEditHarvest',
    ]),
    ponds() {
      return this.$store.state.farm.overview.ponds
    },
    refresh_data() {
      return this.$store.state.farm.overview.refresh_data
    },
    cost_enabled() {
      return this.$store.state.setting.cost
    },
  },
  watch: {
    refresh_data(value) {
      if (value) this.getData(true)
    },
  },
  created() {
    this.getData(true)
  },
  methods: {
    addHarvest() {
      this.harvestForm = null
      this.isModalOpen = true
    },
    async getData(refresh = false) {
      this.$loading()
      this.data = []

      for (let i = 0; i < this.ponds.length; i++) {
        let pond = this.ponds[i]

        if (pond.selected_cycle) {
          if (refresh || !pond.stock) {
            pond.stock = await this.$store.dispatch('farm/getStockOverview', pond)
          }
          if (refresh || !pond.harvest) {
            pond.harvest = []
            let stock = null

            if (pond.stock && pond.stock.length) {
              stock = pond.stock.find(e => e.cycle_id === pond.selected_cycle.cycle_id)
            }

            if (stock) {
              let harvest = await this.$store.dispatch('farm/getHarvest', stock.stock_id)
              if (!harvest.error && harvest.data && harvest.data.length) {
                pond.harvest = harvest.data.map(e => {
                  e.pond_name = pond.name
                  e.block_id = pond.block_id
                  e.pond_id = pond.pond_id
                  e.stock = stock
                  e.cycle = pond.selected_cycle
                  return e
                })
              }
            }
          }
        }
      }

      this.filterData()

      this.$loading(false)
      this.$store.dispatch('farm/refreshOverview', false)
    },

    filterData(type = this.$t('all')) {
      this.filterType = type
      this.data = []
      this.totalPartial = 0
      this.data = this.ponds.reduce((a, b) => {
        if (b.harvest) {
          let totalPartial = b.harvest.filter(e => !e.is_harvest_total).length
          if (totalPartial > this.totalPartial) this.totalPartial = totalPartial

          if (this.filterType === this.$t('all')) a = a.concat(b.harvest)
          else if (typeof this.filterType === 'number') {
            let partial = b.harvest.filter(e => !e.is_harvest_total)
            if (partial[this.filterType - 1]) a.push(partial[this.filterType - 1])
          } else if (this.filterType === 'Total') {
            let partial = b.harvest.filter(e => e.is_harvest_total)
            a = a.concat(partial)
          }
        }
        return a
      }, []).sort((a, b) => {
        if (a.pond_name === b.pond_name) return b.created_at > a.created_at ? 1 : -1

        return a.pond_name.localeCompare(b.pond_name, undefined, {
          numeric: true,
          sensitivity: 'base',
        })
      })
    },

    editHarvest(harvest) {
      this.harvestForm = this._.cloneDeep(harvest)
      this.isModalOpen = true
    },
    deleteHarvest(id) {
      this.$delete(async () => {
        this.$loading()
        await this.$store.dispatch('farm/deleteHarvest', id)
        await this.getData(true)
        this.$loading(false)
      })
    },
  }
}
</script>
