<template>
  <div>
    <sg-top-overlay :back-url="`/farm/${selectedFarm.farm_id}`">
      <div class="has-text-centered-mobile">
        <b-button type="is-primary" outlined class="mr-3" @click="openCycleForm('start')" data-first-step="0">
          {{ $t('cycle.start') }} {{ $t('cycle.cycle') }}
        </b-button>
        <b-button type="is-primary" outlined @click="openCycleForm('end')">
          {{ $t('cycle.end') }} {{ $t('cycle.cycle') }}
        </b-button>
      </div>
    </sg-top-overlay>

    <div class="is-flex is-flex-wrap-wrap is-align-items-center mb-5">
      <h2 class="is-size-5 has-text-weight-bold has-text-dark mr-6 my-3">{{ $t('cycle.cycle') }}</h2>

      <div class="is-flex is-align-items-center">
        <div>
          <p class="is-size-7 mb-2">{{ $t('select') }} {{ $t('farm.block') }}</p>
          <sg-select :items="selectedFarm.blocks" type="is-secondary is-light"
                     :prepend="$t('farm.block')"
                     class="mr-2 mb-2"
                     label="block_name"
                     v-model="selectedBlock"
                     @change="refreshOverview"
                     :placeholder="`${$t('select')} ${$t('farm.block')}`"/>
        </div>

        <div data-first-step="1">
          <p class="is-size-7 mb-2">{{ $t('farm.cycle_by_date') }}</p>
          <b-datepicker
              :placeholder="$t('date')"
              icon="calendar-text"
              locale="id-ID"
              v-model="selectedDate"
              @input="refreshOverview"
              class="mr-2 mb-2">
          </b-datepicker>
        </div>
      </div>
    </div>

    <sg-tab v-model="selectedAction" :items="actions" class="border-top-0 mb-4" data-first-step="2"/>

    <div v-if="!cycle_loading && !stock_loading">
      <stock-cycle :selected-block="selectedBlock"
                   data-third-step="0"
                   ref="stockCycle"
                   v-if="selectedAction === $t('cycle.cycle') + ' ' + $t('stock.stock')"/>
      <harvest v-if="selectedAction === $t('harvest.harvest')"/>
      <sampling :selected-block="selectedBlock" v-if="selectedAction === 'Sampling'"/>
      <disease v-if="selectedAction === $t('disease')"/>
      <death-count v-if="selectedAction === $t('death.death')"/>
    </div>

    <v-tour name="noCycle" :steps="firstSteps"></v-tour>
    <v-tour name="firstStock" :steps="thirdSteps"></v-tour>

    <!--  Modal  -->

    <b-modal
        v-model="cycleModal"
        has-modal-card
        :destroy-on-hide="true"
        aria-role="dialog"
        aria-modal>
      <template #default="props">
        <input-start-end-cycle is-modal @close="props.close" @submit="handleAddCycle"
                               :filter-type="type"></input-start-end-cycle>
      </template>
    </b-modal>

  </div>
</template>

<script>
import SgTopOverlay from "@/components/Sg/SgTopOverlay";
import {mapGetters, mapState} from "vuex";
import SgTab from "@/components/Sg/SgTab";
import SgSelect from "@/components/Sg/SgSelect";
import StockCycle from "@/components/Farm/Overview/StockCycle";
import InputStartEndCycle from "@/components/Farm/Sidebar/InputStartEndCycle";
import Harvest from "@/components/Farm/Overview/Harvest";
import Sampling from "@/components/Farm/Overview/Sampling";
import Disease from "@/components/Farm/Overview/Disease";
import DeathCount from "@/components/Farm/Overview/DeathCount";

export default {
  name: "CycleOverview",
  components: {
    DeathCount,
    Disease,
    Sampling,
    Harvest,
    InputStartEndCycle,
    StockCycle,
    SgSelect,
    SgTab,
    SgTopOverlay,
  },
  data() {
    return {
      firstSteps: [
        {
          target: '[data-first-step="0"]',  // We're using document.querySelector() under the hood
          header: {
            title: 'Mulai menggunakan Sgara dengan mudah!',
          },
          content: `Tekan tombol <b>Mulai Siklus</b> untuk mulai melakukan input data`
        },
        {
          target: '[data-first-step="1"]',
          content: 'Lihat Data Laporan Siklus sesuai dengan Blok dan Tanggal yang dipilih'
        },
        {
          target: '[data-first-step="2"]',
          content: 'Kamu bisa melihat data yang sudah kamu input disini',
          // params: {
          //   placement: 'top' // Any valid Popper.js placement. See https://popper.js.org/popper-documentation.html#Popper.placements
          // }
        },
        {
          target: '[data-first-step="0"]',
          content: 'Yuk mulai siklus mu sekarang juga!',
        },
      ],
      thirdSteps: [
        {
          target: '[data-third-step="0"]',  // We're using document.querySelector() under the hood
          header: {
            title: 'Berhasil menambahkan stock pertama!',
          },
          content: `Kamu bisa langsung melihat data yang sudah kamu input disini`
        },
        {
          target: '.back-url',
          content: '<b>Kembali ke menu</b> untuk melakukan input data lainnya'
        },
      ],
      selectedAction: this.$t('cycle.cycle') + ' ' + this.$t('stock.stock'),
      selectedBlock: null,
      selectedPond: null,
      selectedCycle: null,
      filteredCycles: [],
      cycle_loading: false,
      stock_loading: false,

      cycleModal: false,
      type: null,
    }
  },
  computed: {
    ...mapGetters('farm', [
      'selectedFarm',
      'hasCycle',
      'hasStock',
    ]),

    ...mapState('farm', [
      'tours',
    ]),

    actions() {
      let res = []

      if (this.canViewStock) res.push(this.$t('cycle.cycle') + ' ' + this.$t('stock.stock'))
      if (this.canViewHarvest) res.push(this.$t('harvest.harvest'))
      if (this.canViewSampling) res.push('Sampling')
      if (this.canViewDisease) res.push(this.$t('disease'))
      if (this.canViewDeath) res.push(this.$t('death.death'))

      return res
    },
    ...mapGetters('farm', [
      'canViewStock',
      'canViewHarvest',
      'canViewSampling',
      'canViewDisease',
      'canViewDeath',
      'canViewCycle',
      'canViewWaterQuality',
    ]),

    selectedDate: {
      get() {
        return this.$store.state.farm.analysis.date
      },
      set(value) {
        this.$store.dispatch('farm/updateSelectedFarmStatusDate', value)
        this.refreshData()
      },
    },

    filteredPonds() {
      if (this.selectedBlock && this.selectedFarm.ponds) return this.selectedFarm.ponds.filter(e => e.block_id === this.selectedBlock.block_id)
      else return []
    },

    refresh_data() {
      return this.$store.state.farm.overview.refresh_data
    },
  },
  watch: {
    refresh_data(value) {
      if (value) this.init()
    },
  },
  created() {
    this.init()
  },
  methods: {
    handleAddCycle({type, isAdd}) {
      if (type === 'start' && isAdd && this.hasCycle === 1) {
        this.$refs.stockCycle.startTour()
      }
    },

    init() {
      this.cycle_loading = true
      this.stock_loading = true
      this.$loading()

      Promise.all(
          [
            ...this.selectedFarm.ponds.map(e => {
              return this.$store.dispatch('farm/getCycleOverview', {pond: e})
            }),
            ...this.selectedFarm.ponds.map(e => {
              return this.$store.dispatch('farm/getStockOverview', e)
            }),
            this.$store.dispatch('farm/getFarmInfo'),
          ]
      ).then(res => {
        if (this.selectedFarm.blocks && this.selectedFarm.blocks.length && ((this.selectedBlock && !this.selectedFarm.blocks.find(e => e.block_id === this.selectedBlock.block_id)) || !this.selectedBlock)) {
          this.selectedBlock = this.selectedFarm.blocks[0]
        }
        this.refreshData()
      }).finally(() => {
        this.cycle_loading = false
        this.stock_loading = false
        this.$loading(false)
      })
    },
    refreshData() {
      this.loaded = false

      let date = this.$timestamp(this.selectedDate)

      let ponds = []

      if (this.selectedBlock) {
        ponds = this._.cloneDeep(this.selectedFarm.ponds.filter(e => e.block_id === this.selectedBlock.block_id)).map(e => {
          e.selected_cycle = e.cycles ? e.cycles.find(f => {
            return date >= f.start_date && (f.end_date === 0 || date <= f.end_date)
          }) : null
          return e
        })
      }

      this.$store.commit('farm/UPDATE_OVERVIEW_PONDS', ponds)

      this.$nextTick(() => {
        this.loaded = true

        if (!this.hasCycle) this.$tours.noCycle.start()

        if (this.hasCycle && !this.hasStock) {
          setTimeout(() => {
            this.$refs.stockCycle.startTour()
          }, 0)
        }

        if (this.tours.showBackToMenuTours) {
          this.$tours.firstStock.start()
        }
      })
    },

    refreshOverview() {
      this.$store.dispatch('farm/refreshOverview')
    },

    openCycleForm(type) {
      this.type = type
      this.cycleModal = true
    },
  }
}
</script>
