<template>
  <div class="card" :class="{'is-shadowless': !isModal}">
    <div class="card-content">
      <header class="mb-5">
        <div class="is-flex is-justify-content-space-between is-align-items-center">
          <h2 class="is-size-6 has-text-primary has-text-weight-bold mr-3">{{ $t('disease') }}</h2>
          <div @click="$emit('close')" v-if="isModal">
            <b-icon class="cursor-pointer" icon="close"/>
          </div>
        </div>
        <div>
          <p class="has-text-secondary mb-3">{{ selectedFarm.name }}</p>
        </div>
      </header>
      <section class="my-2">
        <ValidationObserver ref="form">
          <form class="mt-3" @submit.prevent="handleSubmit">
            <div class="columns is-tablet">
              <div class="column is-half-tablet py-0">
                <ValidationProvider :name="$t('farm.block')" rules="required" v-slot="{ errors }">
                  <b-field :label="$t('farm.block')" :type="errors[0] ? 'is-danger' : ''" :message="errors[0]">
                    <sg-select :items="selectedFarm.blocks" type="is-secondary is-light"
                               :disabled="defaultValue ? true : false"
                               label="block_name"
                               v-model="form.block"
                               @change="form.pond = null"
                               :placeholder="`${$t('select')} ${$t('farm.block')}`" expanded/>
                  </b-field>
                </ValidationProvider>
              </div>
              <div class="column is-half-tablet py-0">
                <ValidationProvider :name="$t('farm.pond')" rules="required" v-slot="{ errors }">
                  <b-field :label="$t('farm.pond')" :type="errors[0] ? 'is-danger' : ''" :message="errors[0]">
                    <sg-select :items="filteredPonds" type="is-secondary is-light" v-model="form.pond"
                               :disabled="defaultValue ? true : false"
                               label="name"
                               @change="onChangePond"
                               :placeholder="`${$t('select')} ${$t('farm.pond')}`" expanded/>
                  </b-field>
                </ValidationProvider>
              </div>

              <div class="column is-half-tablet py-0">
                <ValidationProvider :name="$t('date')" rules="required" v-slot="{ errors }">
                  <b-field :label="$t('date')" :type="errors[0] ? 'is-danger' : ''" :message="errors[0]">
                    <b-datepicker
                        :placeholder="$t('date')"
                        icon="calendar-text"
                        locale="id-ID"
                        @input="handleDoc"
                        v-model="form.date">
                    </b-datepicker>
                  </b-field>
                </ValidationProvider>
              </div>
              <div class="column is-half-tablet py-0">
                <b-field label="DOC">
                  <b-input disabled v-model="doc"/>
                </b-field>
              </div>
            </div>

            <ValidationProvider :name="$t('disease')" rules="required" v-slot="{ errors }">
              <b-field :label="$t('disease')" :type="errors[0] ? 'is-danger' : ''" :message="errors[0]">
                <sg-select :items="diseaseMaster" type="is-secondary is-light" v-model="form.disease"
                           label="disease_name"
                           :placeholder="`${$t('select')} ${$t('disease')}`" expanded/>
              </b-field>
            </ValidationProvider>

            <b-field :label="$t('image')">
              <div class="is-flex is-flex-wrap-wrap">
                <b-upload v-model="form.images" multiple drag-drop accept="image/*"
                          class="sg-uploader mr-4 mb-4">
                  <b-icon icon="image-outline" size="is-large"></b-icon>
                  <b-icon icon="plus" class="add-icon"></b-icon>
                </b-upload>

                <div v-for="(image,k) in images" :key="k" class="sg-uploader mr-4 mb-4">
                  <div class="img-overlay" v-if="image.photo">
                    <div @click="deleteFiles(k, image)">
                      <b-icon icon="trash-can-outline" class="remove-icon"/>
                    </div>
                    <img :src="image.photo" alt=""/>
                  </div>
                  <div class="img-overlay" v-else>
                    <div @click="deleteFiles(k)">
                      <b-icon icon="trash-can-outline" class="remove-icon"/>
                    </div>
                    <img :src="image" alt=""/>
                  </div>
                </div>
              </div>
            </b-field>

          </form>

        </ValidationObserver>
      </section>
      <footer class="has-text-right">
        <b-button type="is-secondary is-light" @click="$emit('close')" v-if="isModal">{{ $t('cancel') }}</b-button>
        <b-button type="is-primary is-gradient" class="ml-2" @click="handleSubmit">{{ $t('save') }}</b-button>
      </footer>
    </div>
  </div>
</template>

<script>
import SgSelect from "@/components/Sg/SgSelect";
import {mapState} from "vuex";
import {CometChat} from "@cometchat-pro/chat";

export default {
  name: "InputDisease",
  components: {SgSelect},
  props: {
    isModal: {
      type: Boolean,
      default: false
    },
    defaultValue: {
      type: Object,
      required: false,
    },
  },
  computed: {
    selectedFarm() {
      return this.$store.state.farm.selectedFarm
    },
    filteredPonds() {
      if (this.defaultValue) return this.selectedFarm.ponds.filter(e => e.pond_id === this.defaultValue.pond_id)
      if (this.form.block && this.selectedFarm.ponds) return this.selectedFarm.ponds.filter(e => e.block_id === this.form.block.block_id && e.status === 2)
      else return []
    },
    ...mapState('user', [
      'cometChatUser',
      'cometChatLoggedIn',
    ]),
    guid() {
      return `sgara${this.selectedFarm.farm_id}`
    },
  },
  data: () => {
    return {
      form: {
        images: [],
        stock: null,
        pond: null,
        block: null,
      },
      images: [],
      diseaseMaster: [],

      delete_images: [],

      doc: '-',
    }
  },
  watch: {
    'form.images': {
      deep: true,
      async handler(values) {
        this.images = []
        if (values && values.length) {
          for (let i = 0; i < values.length; i++) {
            const file = values[i];
            this.images.push(typeof file === 'object' && file.photo ? file : URL.createObjectURL(file));
          }
        }
      }
    }
  },
  async created() {
    this.resetForm()
    await this.getMasterData()
  },
  methods: {
    resetForm() {
      if (this.defaultValue) {
        this.form = {
          date: new Date(this.defaultValue.created_at),
          block: this.selectedFarm.blocks.find(e => e.block_id === this.defaultValue.block_id),
          pond: this.filteredPonds.find(e => e.pond_id === this.defaultValue.pond_id),
          disease: this.diseaseMaster.find(e => e.disease_master_id === this.defaultValue.disease_master_id),
          images: this.defaultValue.photos || [],
        }
        this.onChangePond()
      }
    },

    handleDoc() {
      this.doc = '-'
      if (this.form.stock && this.form.date) this.doc = this.$getDOC(this.form.stock.stock_date, this.form.date)
    },

    async onChangePond() {
      if (this.form.pond && this.form.pond.pond_id) {
        this.$loading()
        this.form.stock = await this.$store.dispatch('farm/getActiveStock', this.form.pond.pond_id)
        this.handleDoc()
        this.$loading(false)
      }
    },

    async getMasterData() {
      this.$loading()
      this.diseaseMaster = []
      let res = await this.$store.dispatch('farm/getDiseaseMaster')
      if (!res.error && res.data) this.diseaseMaster = res.data

      if (this.defaultValue) {
        this.form.disease = this.diseaseMaster.find(e => e.disease_master_id === this.defaultValue.disease_master_id)
      }

      this.$loading(false)
    },
    deleteFiles(index, item = null) {
      if (item) {
        this.$delete(async () => {
          this.delete_images.push(item)
          this.form.images.splice(index, 1)
        }, false, 'Are you sure you want to delete this image?')
      } else this.form.images.splice(index, 1)
    },
    async handleSubmit() {
      this.$refs['form'].validate().then(async success => {
        if (success) {
          this.$loading()

          if (this.defaultValue && this.defaultValue.disease_id) {
            let photos = (await Promise.all(
                this.form.images
                    .filter(e => !e.photo)
                    .map(e => this.$base64(e))
            )).map(e => {
              return {photo: e}
            })

            if (this.delete_images.length) {
              for (let i = 0; i < this.delete_images.length; i++) {
                let delImage = this.delete_images[i]
                await this.$store.dispatch('farm/deleteDiseasePhoto', delImage.photo_id)
              }
            }

            await this.$store.dispatch('farm/updateDisease', {
              farm_id: this.selectedFarm.farm_id,
              pond_id: this.form.pond.pond_id,
              cycle_id: this.defaultValue.cycle_id,
              disease_id: this.defaultValue.disease_id,
              disease_master_id: this.form.disease.disease_master_id,
              created_at: this.$timestamp(this.form.date),
              photos: photos,
            })
          } else {
            let pond = this.form.pond
            let cycle = await this.$store.dispatch('farm/getActiveCycle', pond.pond_id)

            if (cycle) {
              let photos = (await Promise.all(this.form.images.map(e => this.$base64(e)))).map(e => {
                return {photo: e}
              })

              await this.$store.dispatch('farm/addDisease', {
                farm_id: this.selectedFarm.farm_id,
                pond_id: this.form.pond.pond_id,
                cycle_id: cycle.cycle_id,
                disease_master_id: this.form.disease.disease_master_id,
                created_at: this.$timestamp(this.form.date),
                photos: photos,
              })
            }
          }
          this.sendMessage(this.form.disease.disease_name + ' ' + this.form.pond.name + ' tanggal ' + this.$dateFormat(this.form.date))
          this.$emit('close')
          this.$loading(false)
          this.$store.dispatch('farm/refreshOverview')
        }
      })
    },
    sendMessage(text) {
      if (this.cometChatLoggedIn) {
        let tag = 'Penyakit'

        let receiverID = this.guid;
        let receiverType = CometChat.RECEIVER_TYPE.GROUP;

        let messageText = `<b>${this.cometChatUser.name}</b>` + " baru saja menginput data " + tag + ' ' + text;
        let textMessage = new CometChat.TextMessage(receiverID, messageText, receiverType);

        textMessage.setTags([tag])

        CometChat.sendMessage(textMessage).then(
            message => {
              console.log("Message sent successfully:", message);
            }, error => {
              console.log("Message sending failed with error:", error);
            }
        );
      }
    },
  }
}
</script>

<style scoped lang="scss">
.card {
  width: 720px !important;
  max-width: 100%;
}
</style>

