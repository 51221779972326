var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card",class:{'is-shadowless': !_vm.isModal}},[_c('div',{staticClass:"card-content"},[_c('header',{staticClass:"mb-5"},[_c('div',{staticClass:"is-flex is-justify-content-space-between is-align-items-center"},[_c('h2',{staticClass:"is-size-6 has-text-primary has-text-weight-bold mr-3"},[_vm._v(_vm._s(_vm.$t('disease')))]),(_vm.isModal)?_c('div',{on:{"click":function($event){return _vm.$emit('close')}}},[_c('b-icon',{staticClass:"cursor-pointer",attrs:{"icon":"close"}})],1):_vm._e()]),_c('div',[_c('p',{staticClass:"has-text-secondary mb-3"},[_vm._v(_vm._s(_vm.selectedFarm.name))])])]),_c('section',{staticClass:"my-2"},[_c('ValidationObserver',{ref:"form"},[_c('form',{staticClass:"mt-3",on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmit.apply(null, arguments)}}},[_c('div',{staticClass:"columns is-tablet"},[_c('div',{staticClass:"column is-half-tablet py-0"},[_c('ValidationProvider',{attrs:{"name":_vm.$t('farm.block'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":_vm.$t('farm.block'),"type":errors[0] ? 'is-danger' : '',"message":errors[0]}},[_c('sg-select',{attrs:{"items":_vm.selectedFarm.blocks,"type":"is-secondary is-light","disabled":_vm.defaultValue ? true : false,"label":"block_name","placeholder":((_vm.$t('select')) + " " + (_vm.$t('farm.block'))),"expanded":""},on:{"change":function($event){_vm.form.pond = null}},model:{value:(_vm.form.block),callback:function ($$v) {_vm.$set(_vm.form, "block", $$v)},expression:"form.block"}})],1)]}}])})],1),_c('div',{staticClass:"column is-half-tablet py-0"},[_c('ValidationProvider',{attrs:{"name":_vm.$t('farm.pond'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":_vm.$t('farm.pond'),"type":errors[0] ? 'is-danger' : '',"message":errors[0]}},[_c('sg-select',{attrs:{"items":_vm.filteredPonds,"type":"is-secondary is-light","disabled":_vm.defaultValue ? true : false,"label":"name","placeholder":((_vm.$t('select')) + " " + (_vm.$t('farm.pond'))),"expanded":""},on:{"change":_vm.onChangePond},model:{value:(_vm.form.pond),callback:function ($$v) {_vm.$set(_vm.form, "pond", $$v)},expression:"form.pond"}})],1)]}}])})],1),_c('div',{staticClass:"column is-half-tablet py-0"},[_c('ValidationProvider',{attrs:{"name":_vm.$t('date'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":_vm.$t('date'),"type":errors[0] ? 'is-danger' : '',"message":errors[0]}},[_c('b-datepicker',{attrs:{"placeholder":_vm.$t('date'),"icon":"calendar-text","locale":"id-ID"},on:{"input":_vm.handleDoc},model:{value:(_vm.form.date),callback:function ($$v) {_vm.$set(_vm.form, "date", $$v)},expression:"form.date"}})],1)]}}])})],1),_c('div',{staticClass:"column is-half-tablet py-0"},[_c('b-field',{attrs:{"label":"DOC"}},[_c('b-input',{attrs:{"disabled":""},model:{value:(_vm.doc),callback:function ($$v) {_vm.doc=$$v},expression:"doc"}})],1)],1)]),_c('ValidationProvider',{attrs:{"name":_vm.$t('disease'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":_vm.$t('disease'),"type":errors[0] ? 'is-danger' : '',"message":errors[0]}},[_c('sg-select',{attrs:{"items":_vm.diseaseMaster,"type":"is-secondary is-light","label":"disease_name","placeholder":((_vm.$t('select')) + " " + (_vm.$t('disease'))),"expanded":""},model:{value:(_vm.form.disease),callback:function ($$v) {_vm.$set(_vm.form, "disease", $$v)},expression:"form.disease"}})],1)]}}])}),_c('b-field',{attrs:{"label":_vm.$t('image')}},[_c('div',{staticClass:"is-flex is-flex-wrap-wrap"},[_c('b-upload',{staticClass:"sg-uploader mr-4 mb-4",attrs:{"multiple":"","drag-drop":"","accept":"image/*"},model:{value:(_vm.form.images),callback:function ($$v) {_vm.$set(_vm.form, "images", $$v)},expression:"form.images"}},[_c('b-icon',{attrs:{"icon":"image-outline","size":"is-large"}}),_c('b-icon',{staticClass:"add-icon",attrs:{"icon":"plus"}})],1),_vm._l((_vm.images),function(image,k){return _c('div',{key:k,staticClass:"sg-uploader mr-4 mb-4"},[(image.photo)?_c('div',{staticClass:"img-overlay"},[_c('div',{on:{"click":function($event){return _vm.deleteFiles(k, image)}}},[_c('b-icon',{staticClass:"remove-icon",attrs:{"icon":"trash-can-outline"}})],1),_c('img',{attrs:{"src":image.photo,"alt":""}})]):_c('div',{staticClass:"img-overlay"},[_c('div',{on:{"click":function($event){return _vm.deleteFiles(k)}}},[_c('b-icon',{staticClass:"remove-icon",attrs:{"icon":"trash-can-outline"}})],1),_c('img',{attrs:{"src":image,"alt":""}})])])})],2)])],1)])],1),_c('footer',{staticClass:"has-text-right"},[(_vm.isModal)?_c('b-button',{attrs:{"type":"is-secondary is-light"},on:{"click":function($event){return _vm.$emit('close')}}},[_vm._v(_vm._s(_vm.$t('cancel')))]):_vm._e(),_c('b-button',{staticClass:"ml-2",attrs:{"type":"is-primary is-gradient"},on:{"click":_vm.handleSubmit}},[_vm._v(_vm._s(_vm.$t('save')))])],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }