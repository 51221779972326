var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card",class:{'is-shadowless': !_vm.isModal}},[_c('div',{staticClass:"card-content"},[_c('header',{staticClass:"mb-5"},[_c('div',{staticClass:"is-flex is-justify-content-space-between is-align-items-center"},[_c('h2',{staticClass:"is-size-6 has-text-primary has-text-weight-bold mr-3"},[_vm._v(_vm._s(_vm.title))]),(_vm.isModal)?_c('div',{on:{"click":function($event){return _vm.$emit('close')}}},[_c('b-icon',{staticClass:"cursor-pointer",attrs:{"icon":"close-thick"}})],1):_vm._e()]),_c('div',[_c('p',{staticClass:"has-text-secondary mb-3"},[_vm._v(_vm._s(_vm.selectedFarm.name))])])]),_c('section',{staticClass:"my-2"},[_c('ValidationObserver',{ref:"form"},[_c('form',{staticClass:"mt-3",on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmit.apply(null, arguments)}}},[(!_vm.defaultValue && !_vm.filterType)?_c('ValidationProvider',{attrs:{"name":"Type","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":((_vm.$t('cycle.start')) + " " + (_vm.$t('or')) + " " + (_vm.$t('cycle.end')) + " " + (_vm.$t('cycle.cycle'))),"type":errors[0] ? 'is-danger' : '',"message":errors[0]}},[_c('b-radio',{attrs:{"name":"type","native-value":"start"},model:{value:(_vm.form.type),callback:function ($$v) {_vm.$set(_vm.form, "type", $$v)},expression:"form.type"}},[_vm._v(" "+_vm._s(_vm.$t('cycle.start'))+" "+_vm._s(_vm.$t('cycle.cycle'))+" ")]),_c('b-radio',{attrs:{"name":"type","native-value":"end"},model:{value:(_vm.form.type),callback:function ($$v) {_vm.$set(_vm.form, "type", $$v)},expression:"form.type"}},[_vm._v(" "+_vm._s(_vm.$t('cycle.end'))+" "+_vm._s(_vm.$t('cycle.cycle'))+" ")])],1)]}}],null,false,3437539768)}):_vm._e(),_c('div',{staticClass:"columns is-tablet"},[(_vm.form.type === 'start' || _vm.defaultValue)?_c('div',{staticClass:"column is-half-tablet"},[_c('ValidationProvider',{attrs:{"name":_vm.$t('cycle.start_cycle'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{staticClass:"mb-0",attrs:{"label":_vm.$t('cycle.start_cycle'),"type":errors[0] ? 'is-danger' : '',"message":errors[0]}},[_c('b-datepicker',{attrs:{"placeholder":((_vm.$t('select')) + " " + (_vm.$t('date'))),"icon":"calendar-text","locale":"id-ID"},model:{value:(_vm.form.start_date),callback:function ($$v) {_vm.$set(_vm.form, "start_date", $$v)},expression:"form.start_date"}})],1)]}}],null,false,877471566)})],1):_vm._e(),(_vm.form.type === 'end' || (_vm.defaultValue && _vm.defaultValue.end_date))?_c('div',{staticClass:"column is-half-tablet"},[_c('ValidationProvider',{attrs:{"name":_vm.$t('cycle.end_cycle'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{staticClass:"mb-0",attrs:{"label":_vm.$t('cycle.end_cycle'),"type":errors[0] ? 'is-danger' : '',"message":errors[0]}},[_c('b-datepicker',{attrs:{"placeholder":((_vm.$t('select')) + " " + (_vm.$t('date'))),"icon":"calendar-text","locale":"id-ID"},model:{value:(_vm.form.end_date),callback:function ($$v) {_vm.$set(_vm.form, "end_date", $$v)},expression:"form.end_date"}})],1)]}}],null,false,1888890990)})],1):_vm._e()]),(!_vm.defaultValue)?_c('div',[_c('p',{staticClass:"mb-3"},[_vm._v(_vm._s(_vm.$t('cycle.cycle_alert')))]),_c('div',{staticClass:"columns is-tablet"},_vm._l((_vm.selected),function(block,key){return _c('div',{key:key,staticClass:"column is-half-tablet"},[_c('div',{staticClass:"mb-3"},[(block.ponds.length)?_c('div',[_c('b-field',[(_vm.form.type === 'start')?_c('b-checkbox',{attrs:{"disabled":!block.ponds.find(function (e) { return e.status <= _vm.startStatus; })},on:{"input":function($event){return _vm.toggleSelectBlock(key)}},model:{value:(block.value),callback:function ($$v) {_vm.$set(block, "value", $$v)},expression:"block.value"}},[_vm._v(" "+_vm._s(_vm.$t('farm.block'))+" "+_vm._s(block.block_name)+" ")]):(_vm.form.type === 'end')?_c('b-checkbox',{attrs:{"disabled":!block.ponds.find(function (e) { return e.status >= _vm.endStatus; })},on:{"input":function($event){return _vm.toggleSelectBlock(key)}},model:{value:(block.value),callback:function ($$v) {_vm.$set(block, "value", $$v)},expression:"block.value"}},[_vm._v(" "+_vm._s(_vm.$t('farm.block'))+" "+_vm._s(block.block_name)+" ")]):_vm._e()],1),_c('div',{staticClass:"is-divider"}),_c('div',{staticClass:"columns is-tablet"},_vm._l((block.ponds),function(pond,k){return _c('div',{key:k,staticClass:"column is-half-tablet"},[_c('div',{staticClass:"card is-bordered",class:{
                            'has-background-primary-light has-border-primary': pond.value
                            }},[_c('label',{class:{
                            'cursor-pointer': _vm.form.type === 'start' ? (pond.status <= _vm.startStatus) : (pond.status >= _vm.endStatus),
                            'cursor-not-allowed': _vm.form.type === 'start' ? (pond.status > _vm.startStatus) : (pond.status  < _vm.endStatus),
                          }},[_c('div',{staticClass:"card-content px-4 py-4 is-flex"},[_c('b-field',{staticClass:"mb-0 is-flex"},[(_vm.form.type === 'start')?_c('b-checkbox',{attrs:{"disabled":pond.status > _vm.startStatus},on:{"input":function($event){return _vm.toggleSelectPond(key)}},model:{value:(pond.value),callback:function ($$v) {_vm.$set(pond, "value", $$v)},expression:"pond.value"}},[_vm._v(_vm._s(pond.name)+" ")]):(_vm.form.type === 'end')?_c('b-checkbox',{attrs:{"disabled":pond.status < _vm.endStatus},on:{"input":function($event){return _vm.toggleSelectPond(key)}},model:{value:(pond.value),callback:function ($$v) {_vm.$set(pond, "value", $$v)},expression:"pond.value"}},[_vm._v(_vm._s(pond.name)+" ")]):_vm._e()],1)],1)])])])}),0)],1):_c('p',[_vm._v("Block "+_vm._s(block.block_name)+" doesn't have any pond")])])])}),0)]):_vm._e()],1)])],1),_c('footer',{staticClass:"has-text-right"},[(_vm.isModal)?_c('b-button',{attrs:{"type":"is-secondary is-light"},on:{"click":function($event){return _vm.$emit('close')}}},[_vm._v(_vm._s(_vm.$t('cancel')))]):_vm._e(),_c('b-button',{staticClass:"ml-2",attrs:{"type":"is-primary is-gradient"},on:{"click":function($event){$event.preventDefault();return _vm.handleSubmit.apply(null, arguments)}}},[_vm._v(_vm._s(_vm.$t('save')))])],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }