<template>
  <div class="card" :class="{'is-shadowless': !isModal}">
    <div class="card-content">
      <header class="mb-5">
        <div class="is-flex is-justify-content-space-between is-align-items-center">
          <h2 class="is-size-6 has-text-primary has-text-weight-bold mr-3">{{ title }}</h2>
          <div @click="$emit('close')" v-if="isModal">
            <b-icon class="cursor-pointer" icon="close-thick"/>
          </div>
        </div>
        <div>
          <p class="has-text-secondary mb-3">{{ selectedFarm.name }}</p>
        </div>
      </header>
      <section class="my-2">
        <ValidationObserver ref="form">
          <form class="mt-3" @submit.prevent="handleSubmit">
            <ValidationProvider name="Type" rules="required" v-slot="{ errors }" v-if="!defaultValue && !filterType">
              <b-field :label="`${$t('cycle.start')} ${$t('or')} ${$t('cycle.end')} ${$t('cycle.cycle')}`"
                       :type="errors[0] ? 'is-danger' : ''" :message="errors[0]">
                <b-radio v-model="form.type"
                         name="type"
                         native-value="start">
                  {{ $t('cycle.start') }} {{ $t('cycle.cycle') }}
                </b-radio>
                <b-radio v-model="form.type"
                         name="type"
                         native-value="end">
                  {{ $t('cycle.end') }} {{ $t('cycle.cycle') }}
                </b-radio>
              </b-field>
            </ValidationProvider>

            <div class="columns is-tablet">
              <div class="column is-half-tablet" v-if="form.type === 'start' || defaultValue">
                <ValidationProvider :name="$t('cycle.start_cycle')" rules="required"
                                    v-slot="{ errors }">
                  <b-field :label="$t('cycle.start_cycle')" class="mb-0"
                           :type="errors[0] ? 'is-danger' : ''" :message="errors[0]">
                    <b-datepicker
                        :placeholder="`${$t('select')} ${$t('date')}`"
                        icon="calendar-text"
                        locale="id-ID"
                        v-model="form.start_date"
                    >
                    </b-datepicker>
                  </b-field>
                </ValidationProvider>
              </div>
              <div class="column is-half-tablet" v-if="form.type === 'end' || (defaultValue && defaultValue.end_date)">
                <ValidationProvider :name="$t('cycle.end_cycle')" rules="required"
                                    v-slot="{ errors }">
                  <b-field :label="$t('cycle.end_cycle')" class="mb-0"
                           :type="errors[0] ? 'is-danger' : ''" :message="errors[0]">
                    <b-datepicker
                        :placeholder="`${$t('select')} ${$t('date')}`"
                        icon="calendar-text"
                        locale="id-ID"
                        v-model="form.end_date"
                    >
                    </b-datepicker>
                  </b-field>
                </ValidationProvider>
              </div>
            </div>

            <div v-if="!defaultValue">
              <p class="mb-3">{{ $t('cycle.cycle_alert') }}</p>

              <div class="columns is-tablet">
                <div class="column is-half-tablet" v-for="(block, key) in selected" :key="key">
                  <div class="mb-3">
                    <div v-if="block.ponds.length">
                      <b-field>
                        <b-checkbox v-model="block.value" @input="toggleSelectBlock(key)"
                                    v-if="form.type === 'start'"
                                    :disabled="!block.ponds.find(e => e.status <= startStatus)">
                          {{ $t('farm.block') }} {{ block.block_name }}
                        </b-checkbox>
                        <b-checkbox v-model="block.value" @input="toggleSelectBlock(key)"
                                    v-else-if="form.type === 'end'"
                                    :disabled="!block.ponds.find(e => e.status >= endStatus)">
                          {{ $t('farm.block') }} {{ block.block_name }}
                        </b-checkbox>
                      </b-field>

                      <div class="is-divider"></div>

                      <div class="columns is-tablet">
                        <div class="column is-half-tablet" v-for="(pond,k) in block.ponds" :key="k">
                          <div class="card is-bordered" :class="{
                              'has-background-primary-light has-border-primary': pond.value
                              }">
                            <label :class="{
                              'cursor-pointer': form.type === 'start' ? (pond.status <= startStatus) : (pond.status >= endStatus),
                              'cursor-not-allowed': form.type === 'start' ? (pond.status > startStatus) : (pond.status  < endStatus),
                            }">
                              <div class="card-content px-4 py-4 is-flex">
                                <b-field class="mb-0 is-flex">
                                  <b-checkbox
                                      v-if="form.type === 'start'"
                                      :disabled="pond.status > startStatus"
                                      v-model="pond.value" @input="toggleSelectPond(key)">{{ pond.name }}
                                  </b-checkbox>
                                  <b-checkbox
                                      v-else-if="form.type === 'end'"
                                      :disabled="pond.status < endStatus"
                                      v-model="pond.value" @input="toggleSelectPond(key)">{{ pond.name }}
                                  </b-checkbox>
                                </b-field>
                              </div>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <p v-else>Block {{ block.block_name }} doesn't have any pond</p>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </ValidationObserver>
      </section>
      <footer class="has-text-right">
        <b-button type="is-secondary is-light" @click="$emit('close')" v-if="isModal">{{ $t('cancel') }}</b-button>
        <b-button type="is-primary is-gradient" class="ml-2" @click.prevent="handleSubmit">{{ $t('save') }}</b-button>
      </footer>
    </div>
  </div>
</template>

<script>
import {mapState} from "vuex";
import {CometChat} from "@cometchat-pro/chat";

export default {
  name: "InputStartEndCycle",
  props: {
    isModal: {
      type: Boolean,
      default: false
    },
    defaultValue: {
      type: Object,
      required: false,
    },
    filterType: String,
    startStatus: {
      type: Number,
      default: 0,
    },
    endStatus: {
      type: Number,
      default: 3,
    }
  },
  computed: {
    title() {
      return this.filterType ? (this.filterType === 'start' ? this.$t('cycle.start') + ' ' + this.$t('cycle.cycle') : this.$t('cycle.end') + ' ' + this.$t('cycle.cycle')) :
          this.$t('cycle.start') + ' ' + this.$t('and') + ' ' + this.$t('cycle.end') + ' ' + this.$t('cycle.cycle')
    },
    selectedFarm() {
      return this.$store.state.farm.selectedFarm
    },
    ...mapState('user', [
      'cometChatUser',
      'cometChatLoggedIn',
    ]),
    guid() {
      return `sgara${this.selectedFarm.farm_id}`
    },
  },
  data: () => {
    return {
      form: {
        selectedBlocks: [],
        selectedPonds: [],
        type: 'start'
      },

      selected: [],
    }
  },
  watch: {
    'form.type'() {
      this.resetSelectedForm()
    },
    selectedFarm: {
      deep: true,
      handler() {
        this.resetSelectedForm()
      }
    }
  },
  created() {
    if (this.filterType) this.form.type = this.filterType
    this.resetSelectedForm()
  },
  methods: {
    resetSelectedForm() {
      if (this.defaultValue) {
        this.form.start_date = new Date(this.defaultValue.start_date)
        this.form.end_date = new Date(this.defaultValue.end_date)
      } else {
        this.selected = []
        this.selected = this.selectedFarm.blocks.map(e => {
          let ponds = this.selectedFarm.ponds.filter(f => f.block_id === e.block_id)
          return {
            block_id: e.block_id,
            block_name: e.block_name,
            value: false,
            ponds: ponds.map(f => {
              return {
                pond_id: f.pond_id,
                name: f.name,
                value: false,
                status: f.status,
              }
            }),
          }
        })
      }
    },

    toggleSelectBlock(key) {
      this.selected[key].ponds.map(e => {
        if (this.form.type === 'start' ? (e.status === 0) : (e.status === 3)) e.value = this.selected[key].value
        return e
      })
    },

    toggleSelectPond(key) {
      let selected = true

      this.selected[key].ponds.forEach(pond => {
        if (!pond.value) {
          selected = false
        }
      })

      this.selected[key].value = selected
    },

    handleSubmit() {
      this.$refs['form'].validate().then(async success => {
        if (success) {
          this.$loading()
          let date = null

          if (this.defaultValue && this.defaultValue.cycle_id) {
            await this.$store.dispatch('farm/updateCycle', {
              cycle_id: this.defaultValue.cycle_id,
              start_date: this.$timestamp(this.form.start_date),
              end_date: this.$timestamp(this.form.end_date),
            })
          } else {
            let selected_ponds = this.selected.reduce((a, b) => {
              a = a.concat(b.ponds.filter(e => e.value))
              return a
            }, [])

            if (this.form.type === 'start') {
              date = this.$dateFormat(this.form.start_date)
              for (let i = 0; i < selected_ponds.length; i++) {
                let pond = selected_ponds[i]

                let res = await this.$store.dispatch('farm/startCycle', {
                  pond_id: pond.pond_id,
                  start_date: this.$timestamp(this.form.start_date)
                })
                console.log(res)
              }
            } else {
              date = this.$dateFormat(this.form.end_date)
              for (let i = 0; i < selected_ponds.length; i++) {
                let pond = selected_ponds[i]

                await this.$store.dispatch('farm/getActiveCycle', pond.pond_id).then(async res => {
                  await this.$store.dispatch('farm/endCycle', {
                    cycle_id: res.cycle_id,
                    end_date: this.$timestamp(this.form.end_date)
                  })
                })
              }
            }

            let text = selected_ponds.map(e => e.name).join(', ') + ' tanggal ' + date
            this.sendMessage(text)
          }

          await this.$store.dispatch('farm/getPond')

          this.resetSelectedForm()
          this.$loading(false)
          this.$emit('close')
          this.$store.dispatch('farm/refreshOverview')
          this.$emit('submit', {
            type: this.filterType,
            isAdd: !this.defaultValue,
          })
        }
      })
    },

    sendMessage(text) {
      if (this.cometChatLoggedIn) {
        let receiverID = this.guid;
        let receiverType = CometChat.RECEIVER_TYPE.GROUP;

        let messageText = `<b>${this.cometChatUser.name}</b>` + " baru saja menginput data " + (this.form.type === 'start' ? 'Mulai Siklus' : 'Akhiri Siklus') + ' ' + text;
        let textMessage = new CometChat.TextMessage(receiverID, messageText, receiverType);

        textMessage.setTags(['Siklus'])

        CometChat.sendMessage(textMessage).then(
            message => {
              console.log("Message sent successfully:", message);
            }, error => {
              console.log("Message sending failed with error:", error);
            }
        );
      }
    },
  }
}
</script>

<style scoped lang="scss">
.card {
  width: 900px !important;
  max-width: 100%;
}
</style>

