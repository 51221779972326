<template>
  <div class="stock-cycle">
    <div class="is-flex is-justify-content-end" v-if="canAddStock && hasCycle && isPro">
      <b-button type="is-primary"
                data-second-step="1"
                class="has-shadow is-gradient mb-3"
                @click="addStock">
        {{ $t('stock.stock') }} {{ $t('shrimp') }}
      </b-button>
    </div>

    <div v-if="data.length" data-second-step="0">
      <div class="card mb-4" v-for="(item, key) in data" :key="key">
        <div class="card-content">
          <div class="columns">
            <div class="column">
              <p class="has-text-weight-bold mb-2">{{ $t('farm.pond') }}</p>
              <p class="is-size-5 has-text-weight-bold has-text-dark">{{ item.pond_name }}</p>
            </div>
            <div class="column">
              <p class="has-text-weight-bold mb-2">{{ isPro ? $t('cycle.cycle') : $t('stock.stock') }}</p>
              <p class="is-size-5 has-text-weight-bold has-text-dark" :class="{ 'has-text-success': !item.end_date }">
                {{ item.cycle_name }}</p>
            </div>
            <div class="column">
              <p class="has-text-weight-bold mb-2">{{ $t('cycle.start_cycle') }}</p>
              <p class="is-size-5 has-text-weight-bold has-text-dark">{{ $dateFormat(item.start_date) }}</p>
            </div>
            <div class="column">
              <p class="has-text-weight-bold mb-2">{{ $t('cycle.end_cycle') }}</p>
              <p class="is-size-5 has-text-weight-bold has-text-dark">
                {{ item.end_date ? $dateFormat(item.end_date) : '-' }}</p>
            </div>
            <div class="column is-flex is-align-items-center is-justify-content-center" v-if="isPro">
              <b-button class="mr-2" type="is-secondary is-light" @click="editCycle(item)"
                        icon-left="pencil-outline"></b-button>
              <b-button type="is-danger is-light" @click="deleteCycle(item)" icon-left="trash-can-outline"></b-button>
            </div>
          </div>

          <div class="is-divider" v-if="item.stock"></div>

          <div class="columns" v-if="item.stock">
            <div class="column">
              <p class="has-text-weight-bold mb-2">{{ $t('stock.stock_date') }}</p>
              <p>{{ $dateFormat(item.stock.stock_date) }}</p>
            </div>
            <div class="column">
              <p class="has-text-weight-bold mb-2">{{ $t('stock.actual_stock') }}</p>
              <p>{{ $customNumberFormat(item.stock.pl_count) }}</p>
            </div>
            <div class="column">
              <p class="has-text-weight-bold mb-2">{{ $t('stock.netto_stock') }}</p>
              <p>{{ $customNumberFormat(item.stock.netto) }}</p>
            </div>
            <div class="column">
              <p class="has-text-weight-bold mb-2">{{ $t('stock.source') }}</p>
              <p>{{ item.stock.pl_source }}</p>
            </div>
            <div class="column is-flex is-align-items-center is-justify-content-center">
              <b-button class="mr-2" type="is-secondary is-light" @click="editStock(item.stock)"
                        icon-left="pencil-outline"></b-button>
              <b-button type="is-danger is-light" @click="deleteStock(item.stock)"
                        icon-left="trash-can-outline"></b-button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <p class="has-text-centered my-4" v-else>{{ $t('no') }} Data</p>

    <!--  Modal Cycle -->

    <b-modal
        v-model="isModalCycle"
        has-modal-card
        :destroy-on-hide="true"
        aria-role="dialog"
        aria-modal>
      <template #default="props">
        <input-start-end-cycle is-modal @close="props.close" @submit="getData(true)" :default-value="cycleForm"/>
      </template>
    </b-modal>

    <!--  Modal Stock -->

    <b-modal
        v-model="isModalStock"
        has-modal-card
        :destroy-on-hide="true"
        aria-role="dialog"
        aria-modal>
      <template #default="props">
        <input-stock-harvest is-modal @close="props.close" type="stock" @submit="handleAddStock"
                             :default-value="stockForm"
        />
      </template>
    </b-modal>
    <v-tour name="firstCycle" :steps="secondSteps"></v-tour>
  </div>
</template>

<script>
import InputStartEndCycle from "@/components/Farm/Sidebar/InputStartEndCycle";
import {mapGetters} from "vuex";
import InputStockHarvest from "@/components/Farm/Sidebar/InputStockHarvest";

export default {
  name: "StockCycle",
  props: {
    selectedBlock: Object,
  },
  components: {InputStockHarvest, InputStartEndCycle},
  data: () => {
    return {
      secondSteps: [
        {
          target: '[data-second-step="0"]',  // We're using document.querySelector() under the hood
          header: {
            title: 'Berhasil menambahkan siklus pertama!',
          },
          content: `Kamu bisa langsung melihat data yang sudah kamu input disini`
        },
        {
          target: '[data-second-step="1"]',
          header: {
            title: 'Tebar Udang!',
          },
          content: 'Setelah memulai siklus, kamu juga perlu untuk menebar udang dengan menekan tombol Tebar Udang'
        },
      ],

      data: [],
      cycleForm: null,
      stockForm: null,
      isModalCycle: false,
      isModalStock: false,
    }
  },
  computed: {
    ...mapGetters('farm', [
      'selectedFarm',
      'canAddStock',
      'canEditCycle',
      'canEditStock',
      'hasCycle',
      'hasStock',
      'isPro',
    ]),

    refresh_data() {
      return this.$store.state.farm.overview.refresh_data
    },

    ponds() {
      return this.selectedFarm.ponds.filter(e => e.block_id === this.selectedBlock.block_id)
    },
  },
  watch: {
    refresh_data(value) {
      if (value) this.getData(true)
    },
    selectedBlock() {
      this.getData()
    },
  },
  created() {
    this.getData()
  },
  methods: {
    async handleAddStock({type, isAdd}) {
      await this.$store.dispatch('farm/getFarmInfo')
      if (type === 'stock' && isAdd && this.hasStock === 1) {
        this.$store.commit('farm/UPDATE_TOURS', {
          tour_name: 'showBackToMenuTours',
          value: true,
        })
      }
      await this.getData(true)
    },

    startTour() {
      this.$tours.firstCycle.start()
    },

    async getData(refresh = false) {
      this.$loading()

      if (refresh && this.cycleForm) {
        let cycle = await this.$store.dispatch('farm/getCycle', this.cycleForm.pond_id)
        if (!cycle.error && cycle.data && cycle.data.length) {
          this.data = this.data.map(e => {
            let find = cycle.data.find(f => {
              return f.cycle_id === e.cycle_id
            })
            if (find) {
              e.start_date = find.start_date
              e.end_date = find.end_date
            }
            return e
          })
        }
      } else {
        this.data = this.ponds.reduce((a, b) => {
          if (b.cycles) {
            b.cycles = b.cycles.map(e => {
              e.stock = b.stock ? b.stock.find(f => f.cycle_id === e.cycle_id) : null
              e.pond_name = b.name
              return e
            })

            a = a.concat(b.cycles)
          }
          return a
        }, []).sort((a, b) => {
          if (b.start_date === a.start_date)
            return a.pond_name.localeCompare(b.pond_name, undefined, {
              numeric: true,
              sensitivity: 'base',
            })
          return b.start_date > a.start_date ? 1 : -1
        })
      }

      this.$loading(false)
      this.$store.dispatch('farm/refreshOverview', false)
    },

    editCycle(cycle) {
      this.cycleForm = this._.cloneDeep(cycle)
      this.isModalCycle = true
    },
    deleteCycle(cycle) {
      const {cycle_id, pond_id} = cycle
      this.$delete(async () => {
        this.$loading()
        let cycle = await this.$store.dispatch('farm/deleteCycle', cycle_id)

        if (!cycle.error) {
          let pond = this.ponds.find(e => e.selected_cycle && e.selected_cycle.cycle_id === cycle_id)

          if (pond) {
            pond.selected_cycle = null
            pond.cycles = pond.cycles.filter(f => f.cycle_id !== cycle_id)
            this.$store.dispatch('farm/changePondStatus', {
              pond_id: pond_id,
              status: 0,
            })
          }
        }
        await this.$store.dispatch('farm/getPond')
        await this.getData(true)
        this.$loading(false)
      })
    },
    addStock() {
      this.stockForm = null
      this.isModalStock = true
    },
    editStock(stock) {
      this.stockForm = this._.cloneDeep(stock)
      this.isModalStock = true
    },
    deleteStock(stock) {
      const {stock_id, pond_id} = stock
      this.$delete(async () => {
        this.$loading()
        let res = this.isPro ?
            await this.$store.dispatch('farm/deleteStock', stock_id) :
            await this.$store.dispatch('farm/deleteStockCycle', stock_id)
        if (!res.error) {
          this.$store.dispatch('farm/changePondStatus', {
            pond_id: pond_id,
            status: this.isPro ? 1 : 0,
          })
        }
        await this.$store.dispatch('farm/getPond')
        this.$store.dispatch('farm/refreshOverview')
        this.$loading(false)
      })
    },
  }
}
</script>

<style lang="scss" scoped>
.stock-cycle {
  .card-content {
    padding: 1.25rem 1.75rem;
  }
}
</style>
